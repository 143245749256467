import { Button, Card, Flex, Group, Image, Text } from '@mantine/core';
import { useEffect } from 'react';

import { Authenticated } from '@/common/components/index.ts';
import { useHeaderProvider } from '@/common/providers/index.ts';
import EstatutoVigentePng from '@/assets/img/estatuto-vigente.png';
import ReglementoCreditosPng from '@/assets/img/reglamento-creditos.png';
import ReglamentoBienestarSocialPng from '@/assets/img/reglamento-bienestar-social.png';
import PoliticaTratamientoDatosPng from '@/assets/img/politica-tratamiento-datos.png';
import ReglamentoJuntaDirectivaPng from '@/assets/img/reglamento-junta-directiva.png';
import ReglamentoComiteContratoSocialPng from '@/assets/img/reglamento-comite-control-social.png';

import styles from './view.module.scss';

type ILegalDocInfo = {
  title: string;
  description?: string;
  linkUrl: string;
  bannerUrl?: string;
};

const LegalDocs: ILegalDocInfo[] = [
  {
    title: 'Estatuto Vigente',
    linkUrl:
      'https://docs.google.com/document/d/e/2PACX-1vR4QfQELED844T3n5OfO3KSjTgsBSQL7C5zPAWSRC0IKedcD1CUlDpI8nKCT2izPA/pub',
    bannerUrl: EstatutoVigentePng
  },
  {
    title: 'Reglamento de Créditos',
    linkUrl:
      'https://docs.google.com/document/d/e/2PACX-1vRi91CCkp72mrLpi_SDEI6Nwnbbl1yhqWF2zxtQWm7onN6DRKmW95OKTFmV1WafHA/pub?urp=gmail_link',
    bannerUrl: ReglementoCreditosPng
  },
  {
    title: 'Reglamento de Bienestar Social',
    linkUrl:
      'https://docs.google.com/document/d/e/2PACX-1vSqjgTs1INHv1HvUWosYMP9gL3K0yWnEs2b6dG50A7jis3cXXWupwX6hYwZ-eLyrQ/pub',
    bannerUrl: ReglamentoBienestarSocialPng
  },
  {
    title: 'Política de Tratamiento de Datos',
    linkUrl:
      'https://docs.google.com/document/d/e/2PACX-1vQZssZbLElysxUP1kqfF0j6LVrdRMZyuXu_zortgrxtvD6zngOH8XJcBYs/pub',
    bannerUrl: PoliticaTratamientoDatosPng
  },
  {
    title: 'Reglamento Junta Directiva',
    linkUrl:
      'https://docs.google.com/document/d/e/2PACX-1vStSKZ3-Jjtwv1ViWmT5xkLH4gO8fttFrI_Qg7ko3kdIyTB0YsYcizDnKV-1ZHxtw/pub',
    bannerUrl: ReglamentoJuntaDirectivaPng
  },
  {
    title: 'Reglamento de Comité de Control Social',
    linkUrl:
      'https://docs.google.com/document/d/e/2PACX-1vRi2pwlk87dH9yxI6fNYQOWs7TPUqgGVJtjMDp53zWdrohvlGUHpivKFaeZ7seUBA/pub',
    bannerUrl: ReglamentoComiteContratoSocialPng
  }
];

const LegalDocumentsView = () => {
  const { setTitle } = useHeaderProvider();

  useEffect(() => {
    setTitle('Reglamentación & Normatividad');
  }, []);

  return (
    <Authenticated>
      <div className={styles.main}>
        <Flex gap={20} wrap={'wrap'} justify={'space-between'}>
          {LegalDocs.map((legalDocInfo) => (
            <Card
              w={300}
              h={300}
              shadow="sm"
              padding="lg"
              radius="md"
              withBorder
              key={legalDocInfo.linkUrl}
              component="a"
              href={legalDocInfo.linkUrl}
              target="_blank"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <Card.Section>
                <Image
                  src={
                    legalDocInfo.bannerUrl ??
                    'https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80'
                  }
                  height={160}
                />
              </Card.Section>

              <Group position="apart" mt="md" mb="xs">
                <Text weight={500}>{legalDocInfo.title}</Text>
              </Group>

              {legalDocInfo.description && (
                <Text size="sm" color="dimmed">
                  {legalDocInfo.description}
                </Text>
              )}

              <Button
                variant="light"
                color="bleudefrance"
                fullWidth
                mt="md"
                radius="md"
              >
                Ver
              </Button>
            </Card>
          ))}
        </Flex>
      </div>
    </Authenticated>
  );
};

export { LegalDocumentsView };
